import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

const styles = (theme) => ({
  content: {
    overflow: 'hidden',
    color: 'white'
  },
  textCollection: {
    marginTop: '-50px'
  },
  textContent: {
    textShadow: '0 10px 50px rgba(0, 0, 0, 0.53)',
    fontSize: '1.66rem',
    fontFamily: 'Bahnschrift',
    lineHeight: '2',
    padding:'15px'
  },
  textContentAuthor: {
    textShadow: '0 10px 50px rgba(0, 0, 0, 0.53)',
    fontSize: '1.66rem',
    lineHeight: '2',
    padding:'15px'
  },
  quote: {
    fontWeight: 'bold',
    letterSpacing: '3px',
    fontFamily: 'Courier',
    fontSize: '100px',
    lineHeight: '1'
  },
  bottomBtn: {
    padding: '8px 10px',
    borderRadius: '4px',
    backgroundColor: '#5fd26e',
    marginTop: '30px',
    display: 'inline-block'
  }
});

function SuspendisBox(props) {
  const {classes, btnText, desc} = props;

  return (
    <div className={classes.content} align="center">
      <Typography className={classes.quote} align="center">
      </Typography>
      <div className={classes.textCollection}>
        <Typography className={classes.textContent} align="center">
        
        </Typography>
        <Typography className={classes.textContent} align="center">
        {props.quote ? props.quote : "“Suspendisse pretium commodo tempor.”"}
        </Typography>
        <Typography className={classes.textContentAuthor} align="right">
        {props.author}
        </Typography>
      </div>
      {btnText ? <span className={classes.bottomBtn}>{btnText}</span> : null}
      {desc && <span className={classes.desc}>{desc}</span>}
    </div>
  );
}

export default withStyles(styles)(SuspendisBox);
