import MetaWheel_Symbol from '~/assets/images/organize/MetaWheel_Symbol.png';
import MetaWheel_Personal from '~/assets/images/organize/MetaWheel_Personal.png';
import MetaWheel_Global from '~/assets/images/organize/MetaWheel_Global.png';
import MetaWheel_Entire from '~/assets/images/organize/MetaWheel_Entire.png';

const organizeLandingData = {
  getAppText: 'GET METAWHEEL APPLICATION',
  image: [MetaWheel_Symbol, MetaWheel_Personal, MetaWheel_Global, MetaWheel_Entire],
  hashText: ["MetaWheelSymbol", "MetaWheelPersonal", "MetaWheelGlobal", "MetaWheelEntire"],
  switcherText: ['METAWHEEL SYMBOL', 'METAWHEEL PERSONAL', 'METAWHEEL GLOBAL', 'METAWHEEL ENTIRE ARCHITECTURE'],
  descriptionTop: [
    '<b>METAWHEEL SYMBOL DESCRIPTION:</b> This symbol represents the Urban Sanctuary Metawheel & Resources Orientation. We use it across our various websites so you can easily identify the Metawheel. The four colours represent the four key aspects of the Metawheel’s layout: Health, Property, Inspiration and Joy — reflecting the interrelated effects of both personal and global advancement in these key areas. The Metawheel has been designed as a human and global advancement architecture to help realise <a href="https://vfh.org.nz/visions/humanity/">Vision For Humanity’s</a> vision of <a href="https://vfh.org.nz/">“A Successful Planet.”</a>',
    '<b>METAWHEEL PERSONAL DESCRIPTION:</b> There are 24 aspects of the Personal Metawheel related to human advancement — arranged in a flow-on series of six key concepts under each main umbrella topic. There are six Health components related to Vitality and Longevity, six Prosperity components related to Wisdom and Success, six Inspiration components related to Empowerment and Significance, and six Joy components related to Clarity and Beauty. Each of these 24 human advancement components is also being crafted into advancement literature, including books and guides — which also contain Planet Impact sections that segue into the global topics.',
    '<b>METAWHEEL GLOBAL DESCRIPTION:</b> The outer ring of the Global Metawheel contains the 27 core subjects related to global development. These key themes are also divided into the four aspects of Health, Prosperity, Inspiration and Joy, each with its own family of essential elements.\
    <br\><br\>\
    The exciting aspect of the Metawheel is that it unfolds and represents how conscious human advancement in each of these four arenas can positively evolve humanity’s future. For example, in relation to Health, <a href="https://vfh.org.nz/visions/health/">Bio-interdependence</a> represents the profound connection between our choices and the living biosphere we depend upon — the constructive health habits we engage in through the Metawheels Vitality and Longevity section positively affecting the health of our planet.\
    <br\><br\>\
    <b>Your personal growth holds the key to global change</b>. The Global Metawheel demonstrates how individual advancement, through self-awareness and compassion, creates ripple effects that benefit humanity and the planet. Contributing to meaningful initiatives and helping others enhances personal happiness and well-being. With deeper understanding, yet minimal effort, your personal progress seamlessly contributes towards <a href="https://vfh.org.nz/get-involved/become-a-vfh-visionaire/">Elevating Global Consciousness</a>, increasing prosperity and joy for others.',
    '<b>METAWHEEL ENTIRE ARCHITECTURE DESCRIPTION:</b> Vision for Humanity (VFH) is the charity behind a network of initiatives which work together to illustrate and help pioneer tangible working models of its <a href="https://vfh.org.nz/visions/">11 Visions</a>.  VFH has an <a href="https://higherconsciousnessnetwork.org/intro/charter">11-point charter</a> which directs all ethos and endeavours, and summarises the 11 Visions of the charity. The Metawheel has been designed by VFH founder Kim Larking as a blue print to enable <a href="https://vfh.org.nz/visions/humanity/">Vision For Humanity’s</a> vision of a <a href="https://vfh.org.nz/">“A Successful Planet.”</a>  All components of the Metawheel interlock to support each other. The Visions around the outer circumference are the key driving force of the original social construct concepts published by VFH in 2012.  '
  ],
  descriptionBottom: [
    '<b>METAWHEEL SYMBOL DESCRIPTION:</b> Look out for the symbol on our websites and apps — the four colours guide you to the specific aspects of growth, whether personal or global, that you are interested in. The rotating orbs, with their matching colours, also signify these areas of growth. Click on an orb to access content related to Health, Prosperity, Inspiration, or Joy.',
    '<b>METAWHEEL PERSONAL DESCRIPTION:</b> The architecture of the personal Metawheel creates a framework for all areas of human growth and advancement. Each aspect of the wheel interlocks to build a pathway to your ultimate holistic human experience, allowing you to “Discover Your Magnificence!”\
    <br\><br\> \
    This holistic self-advancement can be mastered through A Self-Advancing Lifestyle (SAL), a key tool offered in the first Vitality and Longevity health guide titled <a href="https://vfhurbansanctuary.com/guide-landing-page"><i>Health Psychology – A Paradigm Shift to Greater Healthspan</i></a>. We are the architects of our own lives, capable of defining who we wish to be and materialising our dreams into reality — creating an abundance of love, health, wealth, and happiness.\
    <br\><br\> \
    Greater still is the insight we can gain from the Global Metawheel, linking how this personal elevation intertwines with the advancement of humanity and the planet. Once enough of us become aware of this, the world can transform and transcend its state of <a href="https://vfh.org.nz/visions/humanity/key-shifts/">Decrease</a>.',
    '<b>METAWHEEL GLOBAL DESCRIPTION:</b> Further, in regards to <a href="https://vfh.org.nz/visions/law/">Prosperity, Intergenerational Equity</a>—which fosters societal cohesion and advancement through social agreements that promote economic justice, equality, fair wealth distribution, and human capital development—is dependent on the six core components of <a href="https://vfh.org.nz/visions/economics/">Wisdom & Success</a>. Through the adoption of authentic personal wealth, the gate opens to shared, universal, and intergenerational wealth.\
    <br\><br\>\
    And so on: the aspects of <b>Unification and Belonging</b> have the potential to evolve from the learning and adoption of the six core components associated with <b>Empowerment and Significance</b> — the development of an individual’s deepest sense of meaning and purpose, having engaged with such things as <a href="https://vfh.org.nz/visions/education/">Integral Education</a> and <a href="https://vfh.org.nz/visions/media/">Significant and Inspirational Media</a> to strengthen family bonds and foster greater community connections, <a href="https://vfh.org.nz/visions/family-community/">social visibility</a>, and inclusion for all.\
    <br\><br\>\
    <a href="https://vfh.org.nz/visions/religion/">Spiritual Authenticity</a>, can evolve from the <b>Clarity & Beauty</b> of one’s higher self—and in turn a world which cultivates and reflects a global perspective of <a href="https://vfh.org.nz/visions/law/">Universal Truths</a> through a lens of oneness can foster societal and spiritual growth, strengthening our shared sense of connection and humanity.',
    '<b>METAWHEEL ENTIRE ARCHITECTURE DESCRIPTION:</b> Transformational change occurs within the individual first, see our <a href="https://vfh.org.nz/visions/self/">Vision For Self</a>, in order for societal change to follow. The model of the Metawheel is to help advance the individual to their fullest capacity and potential – while grooming ecosystems and social constructs to accommodate.  Through the evolution of human magnificence (You), comes the advancement of the planet. At Vision for Humanity, we believe that by empowering the individual, we create the foundation for global change. \
    <br\><br\>The entire architecture of the Metawheel focuses on humanities ultimate potential, it integrates the VFH <a href="https://vfh.org.nz/visions/">Visions</a>, Initiatives such as <a href="https://www.biosphericallycorrect.org/">Biospherically Correct</a>, <a href="https://visionproductsdirect.com/">Vision Products</a>, <a href="https://vfhurbansanctuary.com/">Urban Sanctuary</a>, <a href="https://sanctuarystore.co.nz/">Sanctuary Store</a> and HCN, and the VFH <a href="https://higherconsciousnessnetwork.org/intro/charter">Charter</a>, forming the blueprint for what we call the <a href="https://higherconsciousnessnetwork.org/intro/metamorphosis">"2nd Genome of Humanity."</a> Human higher purpose resides in working together as a global family. Through this framework, we reveal how it’s possible for us all to contribute to a fully functional and harmonious planet while becoming the best version of ourselves. Participate in creating <a href="https://vfh.org.nz/visions/humanity/">“A Successful Planet”</a>, to help achieve advanced lives for all.'
  ]
};

export default organizeLandingData;
