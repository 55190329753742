const {emailValidation, passwordValidation} = require('../utils/inputValidation');

module.exports.login = [
  {
    inputFieldProps: {
      label: 'Email',
      key: 'email',
      type: 'text',
      autoComplete: 'email',
    },
    initialValue: '',
    gridItemProps: {
      xs: 11,
      sm: 10,
    },
    validationSchema: emailValidation(),
  },
  {
    inputFieldProps: {
      label: 'Password',
      key: 'password',
      type: 'password',
      autoComplete: 'password',
    },
    initialValue: '',
    gridItemProps: {
      xs: 11,
      sm: 10,
    },
    validationSchema: passwordValidation(),
  },
  {
    inputFieldProps: {
      label: 'SIGN IN',
      type: 'submit',
      fullWidth: true,
      variant: 'contained',
    },
    gridItemProps: {
      xs: 6,
      sm: 5,
    },
    // validationSchema: nameValidation('First name'),
  },
];
