const {
  nameValidation,
  emailValidation,
  passwordValidation,
  passwordConfirmValidation,
  requiredCheckboxValidation,
} = require('../utils/inputValidation');
// const {Term} = require('../../components/VisionaireForm/Sections/Term');

module.exports.register = (firstName, email) => [
  {
    inputFieldProps: {
      label: 'First name',
      key: 'firstName',
      type: 'text',
    },
    initialValue: firstName ?? '',
    gridItemProps: {
      xs: 12,
      md: 9,
    },
    validationSchema: nameValidation('First name'),
  },
  {
    inputFieldProps: {
      label: 'Last name',
      key: 'lastName',
      type: 'text',
    },
    initialValue: '',
    gridItemProps: {
      xs: 12,
      md: 9,
    },
    validationSchema: nameValidation('Last name'),
  },
  // {
  //   inputFieldProps: {
  //     label: 'Country',
  //     key: 'country',
  //     type: 'select',
  //   },
  //   options: countryList.getData().map((element) => ({label: element.name, value: element.code})),
  //   initialValue: 'NZ',
  //   gridItemProps: {
  //     xs: 12,
  //     md: 9,
  //   },
  // },
  {
    inputFieldProps: {
      label: 'Email',
      key: 'email',
      type: 'text',
    },
    initialValue: email ?? '',
    gridItemProps: {
      xs: 12,
      md: 9,
    },
    validationSchema: emailValidation(),
  },
  {
    inputFieldProps: {
      label: 'Password',
      key: 'password',
      type: 'password',
    },
    initialValue: '',
    gridItemProps: {
      xs: 12,
      md: 9,
    },
    validationSchema: passwordValidation(),
  },
  {
    inputFieldProps: {
      label: 'Confirm password',
      key: 'passwordConfirm',
      type: 'password',
    },
    initialValue: '',
    gridItemProps: {
      xs: 12,
      md: 9,
    },
    validationSchema: passwordConfirmValidation('password'),
  },
  // {
  //   inputFieldProps: {
  //     label: 'What products and services are you most interested in?',
  //     key: 'signupQuestion1',
  //     type: 'multipleSelect',
  //   },
  //   options: [
  //     {
  //       label: 'Food and drinks',
  //       value: 1,
  //     },
  //     {
  //       label: 'Skincare',
  //       value: 2,
  //     },
  //   ],
  //   initialValue: [],
  //   gridItemProps: {
  //     xs: 12,
  //     md: 9,
  //   },
  // },
  // {
  //   inputFieldProps: {
  //     label: 'How did you hear about us?',
  //     key: 'signupQuestion2',
  //     type: 'select',
  //   },
  //   options: [
  //     {
  //       label: 'Google Search',
  //       value: 1,
  //     },
  //     {
  //       label: 'Newspaper',
  //       value: 3,
  //     },
  //   ],
  //   initialValue: '',
  //   gridItemProps: {
  //     xs: 12,
  //     md: 9,
  //   },
  // },
  {
    inputFieldProps: {
      label: 'I agree with <a href="https://visionproductsdirect.com/pages/terms-and-conditions">terms and conditions</a>',
      key: 'tacCheckbox',
      type: 'checkbox',
    },
    initialValue: false,
    // ModalComponent: Term,
    // ModalComponent: <></>,
    gridItemProps: {
      xs: 12,
      md: 9,
    },
    validationSchema: requiredCheckboxValidation('Accept Terms & Conditions is required.'),
  },
  {
    inputFieldProps: {label: 'SIGN UP', type: 'submit', fullWidth: true, variant: 'contained'},
    gridItemProps: {
      xs: 6,
      md: 4,
      sm: 5,
    },
  },
];
