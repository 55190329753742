//sample data for Intro/Charter page

const introCharterData = [
  {
    cardTitleText:
      'We are all Creators, with a life purpose to be our fullest capacity and most magnificent expression, allowing success to enrobe us, while forgoing ego.',
    cardMainText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Phasellus faucibus scelerisque eleifend donec. Praesent semper feugiat nibh sed pulvinar proin gravida. Turpis nunc eget lorem dolor sed viverra ipsum. Proin fermentum leo vel orci porta non pulvinar neque laoreet. Nisl condimentum id venenatis a. Ipsum consequat nisl vel pretium. Eget dolor morbi non arcu risus. Id consectetur purus ut faucibus. Nulla posuere sollicitudin aliquam ultrices. Etiam erat velit scelerisque in.'
  },
  {
    cardTitleText:
      'Communication of our oneness is the key to earth harmony, with each ones higher purpose being to use their gifts and surplus capacity to Increase more (intergenerational- global family wealth) for all.  ',
    cardMainText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Phasellus faucibus scelerisque eleifend donec. Praesent semper feugiat nibh sed pulvinar proin gravida. Turpis nunc eget lorem dolor sed viverra ipsum. Proin fermentum leo vel orci porta non pulvinar neque laoreet. Nisl condimentum id venenatis a. Ipsum consequat nisl vel pretium. Eget dolor morbi non arcu risus. Id consectetur purus ut faucibus. Nulla posuere sollicitudin aliquam ultrices. Etiam erat velit scelerisque in.'
  },
  {
    cardTitleText:
      'All Health; physical, mental, emotional, spiritual and ecological, is bio- interdependent between people and planet – bestowing a powerful life force of positive feeling states, vitality and longevity. ',
    cardMainText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Phasellus faucibus scelerisque eleifend donec. Praesent semper feugiat nibh sed pulvinar proin gravida. Turpis nunc eget lorem dolor sed viverra ipsum. Proin fermentum leo vel orci porta non pulvinar neque laoreet. Nisl condimentum id venenatis a. Ipsum consequat nisl vel pretium. Eget dolor morbi non arcu risus. Id consectetur purus ut faucibus. Nulla posuere sollicitudin aliquam ultrices. Etiam erat velit scelerisque in.'
  },
  {
    cardTitleText:
      "All human design (goods, services and built form) must synchronise and integrate with the systems of the earth's biosphere",
    cardMainText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Phasellus faucibus scelerisque eleifend donec. Praesent semper feugiat nibh sed pulvinar proin gravida. Turpis nunc eget lorem dolor sed viverra ipsum. Proin fermentum leo vel orci porta non pulvinar neque laoreet. Nisl condimentum id venenatis a. Ipsum consequat nisl vel pretium. Eget dolor morbi non arcu risus. Id consectetur purus ut faucibus. Nulla posuere sollicitudin aliquam ultrices. Etiam erat velit scelerisque in.'
  },
  {
    cardTitleText:
      'Only economics of genuine Increase, building social and ecological intergenerational equity, sustainability creates more value and wealth.',
    cardMainText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Phasellus faucibus scelerisque eleifend donec. Praesent semper feugiat nibh sed pulvinar proin gravida. Turpis nunc eget lorem dolor sed viverra ipsum. Proin fermentum leo vel orci porta non pulvinar neque laoreet. Nisl condimentum id venenatis a. Ipsum consequat nisl vel pretium. Eget dolor morbi non arcu risus. Id consectetur purus ut faucibus. Nulla posuere sollicitudin aliquam ultrices. Etiam erat velit scelerisque in.'
  },
  {
    cardTitleText:
      "Our future requires us to use our best 'Philosopher Kings' to teach an integral education expanding contextual intelligence from truth, of self and societal construct, to enable our children advanced literacy for all.",
    cardMainText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Phasellus faucibus scelerisque eleifend donec. Praesent semper feugiat nibh sed pulvinar proin gravida. Turpis nunc eget lorem dolor sed viverra ipsum. Proin fermentum leo vel orci porta non pulvinar neque laoreet. Nisl condimentum id venenatis a. Ipsum consequat nisl vel pretium. Eget dolor morbi non arcu risus. Id consectetur purus ut faucibus. Nulla posuere sollicitudin aliquam ultrices. Etiam erat velit scelerisque in.'
  },
  {
    cardTitleText: 'All politics must serve the people and be of the peoples mind and volition.',
    cardMainText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Phasellus faucibus scelerisque eleifend donec. Praesent semper feugiat nibh sed pulvinar proin gravida. Turpis nunc eget lorem dolor sed viverra ipsum. Proin fermentum leo vel orci porta non pulvinar neque laoreet. Nisl condimentum id venenatis a. Ipsum consequat nisl vel pretium. Eget dolor morbi non arcu risus. Id consectetur purus ut faucibus. Nulla posuere sollicitudin aliquam ultrices. Etiam erat velit scelerisque in.'
  },
  {
    cardTitleText:
      'Everyone’s needs can be met; physically, mentally, emotionally and spiritually through both love and science - we are all visible and significant, equally sharing in an accountability to participate and inspire.',
    cardMainText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Phasellus faucibus scelerisque eleifend donec. Praesent semper feugiat nibh sed pulvinar proin gravida. Turpis nunc eget lorem dolor sed viverra ipsum. Proin fermentum leo vel orci porta non pulvinar neque laoreet. Nisl condimentum id venenatis a. Ipsum consequat nisl vel pretium. Eget dolor morbi non arcu risus. Id consectetur purus ut faucibus. Nulla posuere sollicitudin aliquam ultrices. Etiam erat velit scelerisque in.'
  },
  {
    cardTitleText:
      'All media is deemed valuable by its ability to improve the integrity of thought and Elevate Global Consciousness',
    cardMainText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Phasellus faucibus scelerisque eleifend donec. Praesent semper feugiat nibh sed pulvinar proin gravida. Turpis nunc eget lorem dolor sed viverra ipsum. Proin fermentum leo vel orci porta non pulvinar neque laoreet. Nisl condimentum id venenatis a. Ipsum consequat nisl vel pretium. Eget dolor morbi non arcu risus. Id consectetur purus ut faucibus. Nulla posuere sollicitudin aliquam ultrices. Etiam erat velit scelerisque in.'
  },
  {
    cardTitleText:
      'All Law must be anchored in Universal Truths which are relevant to all peoples, times and places and identified by a justice which brings forth the individual and society.',
    cardMainText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Phasellus faucibus scelerisque eleifend donec. Praesent semper feugiat nibh sed pulvinar proin gravida. Turpis nunc eget lorem dolor sed viverra ipsum. Proin fermentum leo vel orci porta non pulvinar neque laoreet. Nisl condimentum id venenatis a. Ipsum consequat nisl vel pretium. Eget dolor morbi non arcu risus. Id consectetur purus ut faucibus. Nulla posuere sollicitudin aliquam ultrices. Etiam erat velit scelerisque in.'
  },
  {
    cardTitleText:
      'All faiths and religions are legitimised by their actions of love, forgiveness, compassion, tolerance and integrity, helping guide the individual and the world to Spiritual Authenticity and Oneness.',
    cardMainText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Phasellus faucibus scelerisque eleifend donec. Praesent semper feugiat nibh sed pulvinar proin gravida. Turpis nunc eget lorem dolor sed viverra ipsum. Proin fermentum leo vel orci porta non pulvinar neque laoreet. Nisl condimentum id venenatis a. Ipsum consequat nisl vel pretium. Eget dolor morbi non arcu risus. Id consectetur purus ut faucibus. Nulla posuere sollicitudin aliquam ultrices. Etiam erat velit scelerisque in.'
  }
];

export default introCharterData;
