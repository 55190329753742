import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useField, useFormikContext } from 'formik';
import {
  Button as ButtonMUI,
  Modal,
  MenuItem,
  TextField,
  Checkbox as CheckboxMui,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import IconUnchecked from '../../assets/icon_unchecked.png';
import IconChecked from '../../assets/icon_checked.png';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    fontSize: '13px',
    color: 'rgba(82,82,82,0.98)',
    minHeight: 16,
  },
  fieldHeight: {
    minHeight: 44,
  },
  input: {
    // minWidth: '200px',
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px',
    },
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#9AA200',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#9AA200',
      },
      '&:hover fieldset': {
        borderColor: '#9AA200',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#9AA200',
      },
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
    },
    marginTop: '10px',
  },
  errorLabel: {
    fontSize: '13px',
    color: 'red',
  },
  unCheckedIcon: {
    width: '20px',
    height: '20px',
    backgroundImage: `url(${IconUnchecked})`,
    backgroundSize: 'cover',
  },
  checkedIcon: {
    backgroundImage: `url(${IconChecked})`,
  },
  checkLable: {
    fontSize: '13px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  nonCheckLable: {
    fontSize: '13px',
  },
}));

export const GreenButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: '#9AA200',
    '&:hover': {
      backgroundColor: '#747a00',
    },
  },
}))(ButtonMUI);

export const GreyButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: 'rgb(140, 140, 140)',
    '&:hover': {
      backgroundColor: 'rgba(62, 62, 62, 1)',
    },
  },
}))(ButtonMUI);


export const Button = ({ label, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.inputLabel}></div>
      <GreenButton className={classes.fieldHeight} {...props.inputFieldProps}>
        {props.inputFieldProps.label}
      </GreenButton>
    </>
  );
};

export const TextInput = ({ label, ...props }) => {
  const classes = useStyles();
  const [field, meta] = useField(props);
  return (
    <>
      <div className={classes.inputLabel}>{label}</div>
      <TextField
        variant="outlined"
        fullWidth
        {...field}
        {...props}
        label={null}
        className={clsx(classes.input, classes.fieldHeight)}
        error={meta.touched && meta.error ? true : false}
        helperText={meta.touched && meta.error ? meta.error : null}
      />
    </>
  );
};

export const PasswordInput = ({ label, ...props }) => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // The usage of InputProps cause a "white" background to appear on the icon button
  return (
    <div style={{
      position: 'relative',
    }}>
      <TextInput
        label={label}
        {...props}
        type={isShowPassword ? 'text' : 'password'}
      />
      <InputAdornment position="end"
        style={{
          position: 'absolute',
          bottom: '22px',
          right: '10px',
        }}>
        <IconButton
          size='small'
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {isShowPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    </div>
  );
};

export const Select = ({ label, ...props }) => {
  const classes = useStyles();
  const [field, meta] = useField(props);

  return (
    <>
      <div className={classes.inputLabel}>{label}</div>
      <TextField
        select
        variant="outlined"
        {...field}
        {...props}
        fullWidth
        label={null}
        className={clsx(classes.input, classes.fieldHeight)}
        error={meta.touched && meta.error ? true : false}
        helperText={meta.touched && meta.error ? meta.error : null}
      >
        {props.options.map((element, index) => {
          return (
            <MenuItem key={index} value={element.value}>
              {element.label}
            </MenuItem>
          );
        })}
      </TextField>
      <div className={classes.errorLabel}>{meta.touched && meta.error ? meta.error : null}</div>
    </>
  );
};

export const MultipleSelect = ({ label, ...props }) => {
  const classes = useStyles();
  const [field, meta] = useField(props);
  return (
    <>
      <div className={classes.inputLabel}>{label}</div>{' '}
      <TextField
        select
        className={clsx(classes.input, classes.fieldHeight)}
        fullWidth
        variant="outlined"
        multiple
        {...field}
        {...props}
        SelectProps={{
          multiple: true,
          value: field.value,
        }}
      >
        {props.options.map((element, index) => {
          return (
            <MenuItem key={index} value={element.value}>
              {element.label}
            </MenuItem>
          );
        })}
      </TextField>
      <div className={classes.errorLabel}>{meta.touched && meta.error ? meta.error : null}</div>
    </>
  );
};

export const Checkbox = ({ label, ...props }) => {
  const classes = useStyles();
  const [field, meta] = useField(props);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CheckboxMui
        {...field}
        {...props.inputFieldProps}
        checkedIcon={<span className={clsx(classes.unCheckedIcon, classes.checkedIcon)} />}
        icon={<span className={classes.unCheckedIcon} />}
      />
      <span
        className={props.ModalComponent ? classes.checkLable : classes.nonCheckLable}
        onClick={props.ModalComponent && handleOpen}
        dangerouslySetInnerHTML={{ __html: props.inputFieldProps.label ?? "" }}
      >
      </span>
      <div className={classes.errorLabel}>{meta.touched && meta.error ? meta.error : null}</div>
      {props.ModalComponent && (
        <Modal open={open} onClose={handleClose}>
          <props.ModalComponent />
        </Modal>
      )}
    </div>
  );
};

export const GetValues = ({ getValues }) => {
  const { values } = useFormikContext();
  useEffect(() => {
    getValues && getValues(values);
  }, [values]);
  return <></>;
};
