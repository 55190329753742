import personalIco from '~/assets/images/icons/sanctuary-150x150.png';
import globalIco from '~/assets/images/icons/globe-150x150.png';
import persInc from '~/assets/images/content/creationCompass/IncreasePersonal.png';
import persDec from '~/assets/images/content/creationCompass/DecreasePersonal.png';
import globInc from '~/assets/images/content/creationCompass/IncreaseGlobal.png';
import globDec from '~/assets/images/content/creationCompass/DecreaseGlobal.png';

const creationCompassData = {
  personal: {
    ico: personalIco,
    increase: {
      title: 'Increase',
      img: persInc,
      text:
      '<b>I = ER</b>\
      <br\> \
      <b>I = (AH)R</b>\
      <br\> \
      <b>E = AH</b>\
      <br\> \
       <b> Glossary of terms:</b>\
        <br\> \
        <u>Definition for the formula of increase:</u> \
        <br\> \
        <span style="font-size: 60px; font-weight: lighter; color: #888; vertical-align: middle;">I</span> \
        =Genuine personal increase, is the incrementally appreciating value of what you are producing physcially, mentally, emotionally and spiritually as a result of an elevated consciousness that is relating and creating in healthy ways concerning all areas of your life.\
        <br\> \
        <span>Here you have consciously choosen to adopt a natural desire for personal advancement and to experience your power to create a genuinely better life for yourself – which intrinsically results in every other person (& the planet) being better off than what they originally where.</span>\
      <br\> \
        <span style="font-size: 60px; font-weight: lighter; color: #888; vertical-align: middle;">E</span> \
        =Elevated consciousness, is a result of greater awareness (A) of; oneself, others, society and the envrironment which you choose to then subject to the higher conponent tools of your Human Spirit (H) love, forgiveness, compassion, tolerance and integrity.\<br\> \
        <span>This your true North and potentai.</span>\
        <br\> \
        <span style="font-size: 60px; font-weight: lighter; color: #888; vertical-align: middle;">R</span> \
        =Healthy relationships, due to an elevated consciousness, you seek above all else healthy\
<b>realtionships in all areas of life concerning; oneself, others, society and the environment.</b> \
        <br\> \
        <span style="font-size: 60px; font-weight: lighter; color: #888; vertical-align: middle;">A</span> \
        =Personal Awareness through being increasingly aware: personally, socially and environmentally we are able to direct new outcomes. We question and direct the thinking of the mind and the actions derived from learnt behaviour. We are aware of what the mind has learnt and what constructive and destructive potential this holds and we value the context of all information. \
        <span>We are aware of own consciousness. Questioning and evaluating the thinking mind. Purposefully directing actions as opposed to automatically responding from learnt behaviour. The desire to obtain greater control of the mind is present.</span>\
        <br\> \
        <span style="font-size: 60px; font-weight: lighter; color: #888; vertical-align: middle;">H</span> \
        =Human spirit, human spirit essence uses high level tools (love, forgiveness, compassion, tolerance and integrity) to form and shape all relationships. Tools that are harder to master at first but induce feelings and outcomes of contentment, happiness and joy.\
        <br\><br\> \
        <i>Explore the full equation document and video at Vision for Humanity - Key Shifts, Vision for Self. </i><a href="https://vfh.org.nz/visions/self/key-shifts/">https://vfh.org.nz/visions/self/key-shifts/</a>'
          
    },
    decrease: {
      title: 'Decrease',
      img: persDec,
      text:
        '<b>d = er</b>\
        <br\> \
        <b>d = (mc)r</b>\
        <br\> \
        <b>e = mc</b>\
        <br\> \
        <b> Glossary of terms:</b>\
        <br\> \
        <u>Definition for the formula of decrease:</u> \
        <br\> \
        <span style="font-size: 60px; font-weight: lighter; color: #888; vertical-align: middle;">d</span> \
        = personal decrease, is an out come of continualy declining value or merit, slowly depleting over time what was originally there in ones life physically, mentally, emotionally and spiritually, as a result of ego consciousness forming unhealthy relationships of all manner.\
        <br\> \
        <span style="font-size: 60px; font-weight: lighter; color: #888; vertical-align: middle;">e</span> \
        = ego consciousness – is the limiting consciousness derived as a result of unaware mind (m) being applied to primary consciousness (C). Ego consciousness does not like being vulnerable in any way and is comforted by having control over others. This is who we mistakenly think we are.\
        <br\> \
        <span style="font-size: 60px; font-weight: lighter; color: #888; vertical-align: middle;">r</span> \
        = relationship, humans exist in relationship to each other and all others things. Consciousness is given form through all kinds of interactions (relationship). Unhealthy relationships are formed through ego consciousness. We all exist in relationship to ourselves, each other, society and the environment.\
        <br\> \
        <span style="font-size: 60px; font-weight: lighter; color: #888; vertical-align: middle;">m</span> \
        = unaware mind is undisciplined, harbours unchallenged beliefs, operates from learnt behaviour without context of understanding and predominantly developes a practical intelligence to function in the world that we are born into.\
        <br\> \
        <span style="font-size: 60px; font-weight: lighter; color: #888; vertical-align: middle;">c</span> \
        = primary consciousness is reactive and naturally resorts to using the primitive tools of our human nature that we know so well; defensiveness, anger, blame, criticism and selfishness to operate by and to navigate the world.\
        <br\><br\> \
        <i>Explore the full equation document and video at Vision for Humanity - Key Shifts, Vision for Self. </i><a href="https://vfh.org.nz/visions/self/key-shifts/">https://vfh.org.nz/visions/self/key-shifts/</a>'
    }
  },
  global: {
    ico: globalIco,
    increase: {
      title: 'Increase',
      img: globInc,
      text:
        '<math style="font-weight: bold;">\
  <msubsup>\
    <mi>I</mi>\
    <mi>G</mi>\
    <mi>∞</mi>\
  </msubsup>\
  <mo>=</mo>\
  <msub>\
    <mi>E</mi>\
    <mi>G</mi>\
  </msub>\
  <mi>R</mi>\
  <mo>^</mo>\
</math>\
      <br\> \
      <math style="font-weight: bold;">\
  <msubsup>\
    <mi>I</mi>\
    <mi>G</mi>\
    <mi>∞</mi>\
  </msubsup>\
  <mo>=</mo>\
  <mo>(</mo>\
  <msub>\
    <mi>A</mi>\
    <mi>G</mi>\
  </msub>\
  <mi>H</mi>\
  <mo>)</mo>\
  <mi>R</mi>\
  <mo>^</mo>\
</math>\
      <br\> \
      <math style="font-weight: bold;">\
  <msub>\
    <mi>E</mi>\
    <mi>G</mi>\
  </msub>\
  <mo>=</mo>\
  <msub>\
    <mi>A</mi>\
    <mi>G</mi>\
  </msub>\
  <mi>H</mi>\
</math>\
      <br\> \
       <b> Glossary of terms:</b>\
        <br\> \
        <u>Definition for the formula of increase:</u> \
        <br\> \
        Unifying co-creation model\
        <br\> \
        <span style="font-size: 60px; font-weight: lighter; color: #888; vertical-align: middle;">\
        I<sub style="vertical-align: text-bottom; font-size: large;">G</sub><sup style="vertical-align: text-top;margin-left: -12px; font-size: large;">&infin;</sup></span> \
        =Infinite global increase is the infinitely appreciating value of what is being co- operatively produced, from and for humanity, as a result of an elevated global consciousness that is being given form through ubiquitous healthy relationships.\
        <br\> \
        <span>Thus allowing for far more advanced lives to be exponentially created for us all due to the sum of what can be  harmoniously achieved together, with nature, being infinitely greater than the results of individualistic effort.</span>\
      <br\> \
        <span style="font-size: 60px; font-weight: lighter; color: #888; vertical-align: middle;">\
        E<sub style="vertical-align: text-bottom; font-size: large;">G</sub></span> \
        =Elevated Global consciousness – the collective consciousness of humanity that is elevated as a result of integrated global awareness ( of self, society and environment) which is then being subjected to the higher conponents of human spirit.<br\> \
        <span>This is humanities true North</span>\
        <br\> \
        <span style="font-size: 60px; font-weight: lighter; color: #888; vertical-align: middle;">\
        R<sub style="vertical-align: top; font-size: large;">^</sub></span> \
        =ubiquitous healthy relationships – due to an elevated global consciousness the way humans relate to each other and all things has become healthy.\
        <br\> \
        <span style="font-size: 60px; font-weight: lighter; color: #888; vertical-align: middle;">\
        A<sub style="vertical-align: text-bottom; font-size: large;">G</sub></span> \
        =global Awareness, individuals globally becoming increasingly aware personally, socially and environmentally to direct new outcomes. Questioning and directing the thinking of the mind and the actions derived from learnt behaviour. Aware of what the mind has learnt, the context to and structure of society and what constructive and destructive potential this all holds.\
        <br\> \
        <span style="font-size: 60px; font-weight: lighter; color: #888; vertical-align: middle;">H</span> \
        =Human spirit – this essence inherent in us all, uses higher level tools (love forgiveness, compassion, tolerance and integrity) to form and shape all relationships. Tools that are harder to master at first, but induce feelings and outcomes of contentment, happiness and  joy.\
        <br\><br\> \
        <i>Explore the full equation document and video at Vision for Humanity - Key Shifts, Vision for Planet. </i><a href="https://vfh.org.nz/visions/humanity/key-shifts/">https://vfh.org.nz/visions/humanity/key-shifts/</a>'
    },
    decrease: {
      title: 'Decrease',
      img: globDec,
      text:
        '<math style="font-weight: bold;">\
  <msubsup>\
    <mi>d</mi>\
    <mi>G</mi>\
    <mi>∞</mi>\
  </msubsup>\
  <mo>=</mo>\
  <msub>\
    <mi>e</mi>\
    <mi>G</mi>\
  </msub>\
  <mi>r</mi>\
</math>\
        <br\> \
        <math style="font-weight: bold;">\
  <msubsup>\
    <mi>d</mi>\
    <mi>G</mi>\
    <mi>∞</mi>\
  </msubsup>\
  <mo>=</mo>\
  <mo>(</mo>\
  <msub>\
    <mi>m</mi>\
    <mi>G</mi>\
  </msub>\
  <mi>c</mi>\
  <mo>)</mo>\
  <mi>r</mi>\
</math>\
        <br\> \
        <math style="font-weight: bold;">\
  <msub>\
    <mi>e</mi>\
    <mi>G</mi>\
  </msub>\
  <mo>=</mo>\
  <msub>\
    <mi>m</mi>\
    <mi>G</mi>\
  </msub>\
  <mi>c</mi>\
</math>\
        <br\> \
        <b> Glossary of terms:</b>\
        <br\> \
        <u>Definition for the formula of decrease:</u> \
        <br\> \
        Separatist competitive model \
        <br\> \
        <span style="font-size: 60px; font-weight: lighter; color: #888; vertical-align: middle;">\
        d<sub style="vertical-align: text-bottom; font-size: large;">G</sub><sup style="vertical-align: text-top;margin-left: -12px; font-size: large;">&infin;</sup>\
        </span> \
        = infinite global decrease – is an outcome of continualy declining value or merit, depleting over time what was originally there personally, socially and environmentally as a result of ego consciousness that is being given form through unhealthy relationships.\
        <br\> \
        <span style="font-size: 60px; font-weight: lighter; color: #888; vertical-align: middle;">\
        e<sub style="vertical-align: text-bottom; font-size: large;">G</sub>\
        </span> \
        =  global ego consciousness – is the limiting collective consciousness of humanity derived as a result of an unaware mind of society being applied to primary group consciousness. Ego consciousness does not like being vulnerable in any way and is comforted by having control over others.\
        <br\> \
        <span style="font-size: 60px; font-weight: lighter; color: #888; vertical-align: middle;">r</span> \
        = relationship – humans exist in relationship to each other and all other things. Consciousness is given form through all forms of relationship. Unhealthy and limiting relationships are formed through ego consciousness.\
        <br\> \
        <span style="font-size: 60px; font-weight: lighter; color: #888; vertical-align: middle;">\
        m<sub style="vertical-align: text-bottom; font-size: large;">G</sub>\
        </span> \
        = unaware mind throughout society relates to what already exists. It is undisciplined, harbours unchallenged beliefs, operates from learnt behaviour without context of understanding and predominantly developes a practical intelligence to function in the world that we are born into.\
        <br\> \
        <span style="font-size: 60px; font-weight: lighter; color: #888; vertical-align: middle;">c</span> \
        = primary group consciousness is reactive and naturally resorts to using the primitive tools of our human nature: defensiveness, anger, blame, criticism and selfishness to operate by and to navigate the world, especially when challenged.\
        <br\><br\> \
        <i>Explore the full equation document and video at Vision for Humanity - Key Shifts, Vision for Planet. </i><a href="https://vfh.org.nz/visions/humanity/key-shifts/">https://vfh.org.nz/visions/humanity/key-shifts/</a>'
    
    }
  }
};

export default creationCompassData;
