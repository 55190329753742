const styles = (theme) => ({
  root: {
    minHeight: 32,
    position: 'relative'
  },
  line: {
    width: 48,
    [theme.breakpoints.down('sm')]: {
      width: 35, // Change width to 20 for mobile devices
    },
    height: 2.2,
    borderRadius: 2,
    marginBottom: 8,
    position: 'relative',
    background: '#000000',
    '&:last-child': {
      marginBottom: 0
    }
  }
});

export default styles;
