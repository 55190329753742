// @ts-check
import React, { useEffect, useState } from "react";
import { AppBar, Button, Grid } from "@material-ui/core";
import { useQuery } from "react-query";
import { fetchHCNArticleCategoriesWarper } from "shared/react-query/strapiAPI";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";


const get1stLevelCategories = (allCategories, mode) => {
  if (!allCategories || allCategories.length === 0) {
    return [];
  }
  return allCategories.filter(
    (e) => e.parentTitle?.toUpperCase() === mode.toUpperCase()
  );
}

const getSelectedCategories = (allCategories, mode, selectedCategoryIds) => {
  if (!allCategories || allCategories.length === 0) {
    return [];
  }

  const newCategories = get1stLevelCategories(allCategories, mode);
  if (selectedCategoryIds.length === 0) {
    return newCategories;
  }

  if (selectedCategoryIds.length === 1) {
    return allCategories.filter((e) => e.parentId === selectedCategoryIds[0]);
  }

  return allCategories.filter((c) => c.id === selectedCategoryIds[1]);
};

export const useCategoryFilter = () => {
  const history = useHistory();
  const location = useLocation();

  const allCategories = useQuery("HCNArticleCategories", () =>
    fetchHCNArticleCategoriesWarper(),
    { staleTime: Infinity }
  );

  const [mode, setMode] = useState("person");

  /** @type {[number[], (a: number[]) => void]} */
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.get("mode")) {
      setMode(params.get("mode").toLowerCase());
    }

    if (params.get("category")) {
      let newCategoryIds = [parseInt(params.get("category"))];
      if (params.get("subcategory")) {
        newCategoryIds.push(parseInt(params.get("subcategory")));
      }

      setSelectedCategoryIds(newCategoryIds);
    }

  }, []);

  // detect mode change on url
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("mode")) {
      if (params.get("mode") !== mode) {
        setMode(params.get("mode").toLowerCase());
        setSelectedCategoryIds([]);
      }
    }
  }, [location.search]);

  //handle subcategory without category and mode
  useEffect(() => {
    if (allCategories.data && allCategories.data.length > 0) {
      const params = new URLSearchParams(location.search);

      if (params.get("subcategory") && !params.get("category")) {
        const subCate = allCategories.data.find((c) => c.id === parseInt(params.get("subcategory")));
        const parentCate = allCategories.data.find((c) => c.id === subCate.parentId);
        setSelectedCategoryIds([parentCate.id, subCate.id]);

        const modeCate = allCategories.data.find((c) => c.id === parentCate.parentId);

        updateHistory(modeCate.title.toLowerCase(), [parentCate.id, subCate.id]);
      }
    }
  }, [allCategories.data]);

  const updateHistory = (newMode, newCategoryIds) => {
    let queryBuilder = new URLSearchParams();
    queryBuilder.set("mode", newMode);
    if (newCategoryIds.length > 0) {
      queryBuilder.set("category", newCategoryIds[0].toString());
    }
    if (newCategoryIds.length > 1) {
      queryBuilder.set("subcategory", newCategoryIds[1].toString());
    }
    history.replace("?" + queryBuilder.toString());
  };

  const handleSetMode = (newMode) => {
    setMode(newMode);
    setSelectedCategoryIds([]);
    updateHistory(newMode, []);
  };

  const handleSetSelectedCategoryIds = (newCategoryIds) => {
    setSelectedCategoryIds(newCategoryIds);
    updateHistory(mode, newCategoryIds);
  };

  const selectedCategories = getSelectedCategories(allCategories.data, mode, selectedCategoryIds);

  return {
    allCategories,
    selectedCategoryIds, setSelectedCategoryIds: handleSetSelectedCategoryIds,
    selectedCategories,
    mode, setMode: handleSetMode,
  }
}

/**
 * @typedef {Object} IndexProps
 * @property {import("shared/react-query/strapiAPI").HCNCategory[]} allCategories
 * @property {string} mode
 * @property {number[]} selectedCategoryIds
 * @property {(cat: import("shared/react-query/strapiAPI").HCNCategory[]) => void} onCategoriesChange
 * @property {Record<string, any>} classes
 */

/**
 * @param {IndexProps} props
 */
const Index = (props) => {
  const { classes, allCategories, mode, selectedCategoryIds, onCategoriesChange } = props;
  // console.log("data", mode, selectedCategoryIds);

  //1level categories
  const categories = get1stLevelCategories(allCategories, mode);

  // 2nd level categories
  const subCategories = selectedCategoryIds.length > 0 ?
    getSelectedCategories(allCategories, mode, [selectedCategoryIds[0]]) :
    null;

  let articlePageCheck = window.location.href.includes("articles");
  const articleBackground = `rgba(255, 255, 255, 0.1)`;

  const handleTabChange = (tab) => {
    let newSelectedCategoryIds = [tab.id];
    if (selectedCategoryIds.length === 1 && selectedCategoryIds[0] === tab.id) {
      newSelectedCategoryIds = [];
    }

    onCategoriesChange(newSelectedCategoryIds);
  };
  const onSubCategoryChange = (categoryId) => {
    let newSelectedCategoryIds = [selectedCategoryIds[0], categoryId];
    if (selectedCategoryIds.length === 2 && selectedCategoryIds[1] === categoryId) {
      newSelectedCategoryIds = [selectedCategoryIds[0]];
    }

    onCategoriesChange(newSelectedCategoryIds);
  };

  if (!allCategories || allCategories.length === 0 || !mode) {
    return <Grid>Loading...</Grid>;
  }

  return (
    <div>
      <AppBar
        position="static"
        style={{
          background: articlePageCheck ? articleBackground : "default",
          border: articlePageCheck ? "1px solid white" : "none",
          color: articlePageCheck ? "white" : "none",
        }}
      >
        <div
          style={{
            overflowX: 'auto',
            display: 'flex',
            justifyContent: 'space-around',
            whiteSpace: 'nowrap',
            gap: '12px',
            padding: '0px 8px'
          }}
        >
          <div

          style={{
            display: "flex",
            flexGrow: 1, // This will make the inner div grow to fill the available space
            justifyContent: 'space-between', // This will distribute the items evenly
            width: '100%' //
          }}
          >
          {categories?.map((tab) => (
            <div
              key={`${mode}-${tab.id}`}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 4,
                cursor: "pointer",
                padding: "16px 24px",
                paddingBottom: "14px",
                borderBottom: selectedCategoryIds.length > 0 && selectedCategoryIds[0] === tab.id ?
                  `2px solid ${tab.color}` :
                  "2px solid transparent",
              }}
              onClick={() => handleTabChange(tab)}
            >
              {/* <img src={tab.icon} alt="Tab Icon" style={{height: 15}} /> */}
              <div
                style={{
                  // borderRadius: "10px",
                  // boxShadow: `0px 0px 0px 3px ${tab.attributes.color}, 0px 0px 0px 2px ${tab.attributes.color}`,
                  // width: "20px",
                  // height: "20px",
                  background: `${tab.color}`,
                  border: "2px solid white",
                  borderRadius: "30px",
                  height: "12px",
                  width: "12px",
                  position: "relative",
                  boxShadow: `0 0 0 1px ${tab.color}`,
                }}
              ></div>

              <span
                id="category"
                style={{
                  fontFamily: "Roboto",
                  color: articlePageCheck ? "white" : "black",
                }}
              >
                {tab.title.split("-")[0]}
              </span>
            </div>
          ))}
          </div>
        </div>
      </AppBar>
      {selectedCategoryIds.length > 0 && (
        <div>
          <div
            className={classes.subCategoryContainer}
            style={{
              display: "flex",
              flexWrap: "nowrap",
              overflow: "auto",
              marginTop: 0,
              // background: "rgb(205 205 205)",
            }}
          >
            {subCategories?.map((cat) => (
              <Button
                key={`${mode}-${cat.id}`}
                color="default"
                className={classes.subCategoryButton}
                style={{
                  whiteSpace: "nowrap",
                  minWidth: "min-content",
                  color: "white !important",
                  borderRadius: 0,
                  borderBottom: selectedCategoryIds.length == 2 && cat.id === selectedCategoryIds[1] ? "2px solid white" : "none",
                }}
                onClick={() => onSubCategoryChange(cat.id)}
              >
                <span
                  style={{
                    width: "2px",
                    height: "8.5px",
                    marginRight: "4px",
                    borderRight: `2px solid ${cat?.color}`,
                  }}
                />{" "}
                <span style={{ color: "white" }}>{cat?.title}</span>
              </Button>
            ))}
          </div>

        </div>
      )}
    </div>
  );
};

export default Index;
