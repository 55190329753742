import React from 'react';
import {Link} from 'react-router-dom';
import ClusterIcons from 'shared/ui/cluster-icons';
import {withStyles, Grid, Container, CardMedia} from '@material-ui/core';
import styles from './DisperseStyles';

//getting data from test data storage
import disperseData from '~/data/disperseData';
import DisperseCard from '~/components/ui/DisperseCard/DisperseCard';

import intl from 'react-intl-universal';
import * as messageKeys from '~/locales';

class Disperse extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      disperseCardData: [],
      showSingle: false
    };
    this.showSingleTrigger = this.showSingleTrigger.bind(this);
    this.getDisperseData = this.getDisperseData.bind(this);
  }

  getDisperseData(showId = null, showAll = false) {
    //get data here
    var disperseDataFiltered = disperseData.Texts;
    var showSingle;
    showSingle = false;
    //select the specified array if the 'id' param is present in url
    if (showId === null && showAll === false) {
      const search = this.props.location.search;
      const params = new URLSearchParams(search);
      showId = params.get('id');
    }
    if (showId) {
      disperseDataFiltered = disperseDataFiltered.filter((a) => a.id.toString() === showId.toString());
      showSingle = true;
    }

    this.setState({disperseCardData: disperseDataFiltered, showSingle: showSingle});
  }

  showSingleTrigger(id) {
    this.setState({
      disperseCardData: [],
      showSingle: true
    });
    this.getDisperseData(id);
  }

  showAllTrigger() {
    this.setState({
      disperseCardData: [],
      showSingle: false
    });
    this.getDisperseData(null, true);
  }

  componentDidMount() {
    this.getDisperseData();
    this._isMounted = true;
  }

  render() {
    const {classes} = this.props;
    return (
      <React.Fragment>
        <ClusterIcons active/>
        {this._isMounted ? (
          <Container className={classes.root}>
            <Grid container>
              <Grid item xs={12} md={12} className={classes.gridColumnTop}>
                <h1 className={classes.myH1}>{intl.get(messageKeys.HEADER_NETWORK_MENU_DISPERSE_LABEL)}</h1>
                <h2 className={classes.myH2}>{intl.get(messageKeys.HEADER_NETWORK_MENU_DISPERSE_DESCRIPTION)}</h2>
                {disperseData.TopPageText}
                {this.state.showSingle ? (
                  <Link to={'?'} onClick={() => this.showAllTrigger()} className={classes.backHref}>
                    {intl.get(messageKeys.BACK_TO_ALL)}
                  </Link>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={8} className={classes.gridColumn1}>
                {this.state.disperseCardData.map((cardData) => (
                  <DisperseCard
                    key={cardData.id}
                    id={cardData.id}
                    image={cardData.image}
                    title={cardData.title}
                    content={cardData.content}
                    tags={cardData.tags}
                    disperseDate={cardData.disperseDate}
                    video={cardData.video}
                    showSingle={this.state.showSingle}
                    showSingleTrigger={this.showSingleTrigger}
                  />
                ))}
              </Grid>
              <Grid item xs={12} md={4} className={classes.gridColumn2}>
                {disperseData.BannersRight.map((Banner) => (
                  <CardMedia className={classes.media} component="img" src={Banner} />
                ))}
              </Grid>
            </Grid>
          </Container>
        ) : (
          <div>Loading...</div>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Disperse);
