import personHealth from '~/assets/images/layout/landing/personHealth.png';
import personInspiration from '~/assets/images/layout/landing/personInspiration.png';
import personProsperity from '~/assets/images/layout/landing/personProsperity.png';
import personJoy from '~/assets/images/layout/landing/personJoy.png';

const styles = (theme) => ({
  content: {
    paddingTop: '5vh'
  },
  humanimg: {
    display: 'block',
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.only('sm')]: {
      display: 'none'
    }
  },
  img: {
    width: '200px',
    height: '200px'
  },
  headingPart: {
    order: 1,
    [theme.breakpoints.only('xs')]: {
      order: 2
    },
    [theme.breakpoints.only('sm')]: {
      order: 2
    }
  },
  spherePart: {
    order: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [theme.breakpoints.only('xs')]: {
      order: 1,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between'
    },
    [theme.breakpoints.only('sm')]: {
      order: 1,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between'
    }
  },

  sphereHealth: {
    [theme.breakpoints.only('xs')]: {
      background: `url(${personHealth})`,
      backgroundSize: 'cover'
    }
  },
  sphereProsperity: {
    [theme.breakpoints.only('xs')]: {
      background: `url(${personProsperity})`,
      backgroundSize: 'cover'
    }
  },
  sphereInspiration: {
    [theme.breakpoints.only('xs')]: {
      background: `url(${personInspiration})`,
      backgroundSize: 'cover'
    }
  },
  sphereJoy: {
    [theme.breakpoints.only('xs')]: {
      background: `url(${personJoy})`,
      backgroundSize: 'cover'
    }
  },

  mainHeading: {
    fontSize: 'calc(6vw + 1.5em)',
    fontWeight: 'bold',
    letterSpacing: '2vw',
    color: 'white',
    marginTop: '2vh',
    textTransform: 'uppercase'
  },

  healthLinkContainer: {
    width: '80%',
    height: '59px',
    borderRadius: '30px',
    backgroundColor: 'rgb(12, 44, 23)',
    display: 'inline-flex',
    justifyContent: 'space-arround',
    alignItems: 'center',
    paddingLeft: '1em',
    paddingRight: '1em',
    [theme.breakpoints.only('xs')]: {
      height: '40px',
      width: '87%',
      fontSize: '1em',
      textAlign: 'center'
    },
    [theme.breakpoints.only('sm')]: {
      width: '90%',
      fontSize: '1em',
      textAlign: 'center'
    }
  },
  prosperityLinkContainer: {
    width: '80%',
    height: '59px',
    borderRadius: '30px',
    backgroundColor: 'rgb(7, 22, 45)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '2em',
    paddingRight: '2em',
    [theme.breakpoints.only('xs')]: {
      height: '40px',
      width: '87%',
      fontSize: '1em',
      textAlign: 'center'
    },
    [theme.breakpoints.only('sm')]: {
      width: '90%',
      fontSize: '1em',
      textAlign: 'center'
    }
  },
  inspirationLinkContainer: {
    width: '80%',
    height: '59px',
    borderRadius: '30px',
    backgroundColor: 'rgb(92, 4, 26)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '2em',
    paddingRight: '2em',
    [theme.breakpoints.only('xs')]: {
      height: '40px',
      width: '87%',
      fontSize: '1em',
      textAlign: 'center'
    },
    [theme.breakpoints.only('sm')]: {
      width: '90%',
      fontSize: '1em',
      textAlign: 'center'
    }
  },
  joyLinkContainer: {
    width: '80%',
    height: '59px',
    borderRadius: '30px',
    backgroundColor: 'rgb(145, 97, 7)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '2em',
    paddingRight: '2em',
    [theme.breakpoints.only('xs')]: {
      height: '40px',
      width: '87%',
      fontSize: '1em',
      textAlign: 'center'
    },
    [theme.breakpoints.only('sm')]: {
      width: '90%',
      fontSize: '1em',
      textAlign: 'center'
    }
  },

  contentDescription: {
    width: '100%',
    fontSize: '1.1em',
    fontFamily: 'Calibri',
    color: 'white',
    margin: '20px auto 6%',
    lineHeight: '1.6',
    letterSpacing: '2px',
    '& a': {
      textDecoration: 'underline',
      fontWeight: 'bold',
      color: 'inherit' // Optional: ensures it matches surrounding text

    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.1em',
      lineHeight: '1.5',
      letterSpacing: '2px'
    }
    
  
  }
});
export default styles;
