const styles = (theme) => ({
  root: {
    display: 'block',
    width: '100%',
    cursor: 'pointer'
  },
  container: {
    position: 'relative',
    width: '100%'
  },
  contentContainer: {
    position: 'absolute',
    backgroundColor: 'rgba(23, 23, 23, 0.73)',
    height: 36,
    bottom: 0,
    left: 0,
    right: 0,
    paddingTop: 10
  },
  title: {
    color: 'white',
    lineHeight: 2,
    fontSize: 13,
    fontWeight: 700,
    letterSpacing: 3
  },
  type: {
    color: 'white',
    lineHeight: 2,
    fontSize: 13,
    fontWeight: 700,
    letterSpacing: 3
  },
  content: {
    color: '#fefefe',
    opacity: 0.5,
    fontSize: 11,
    letterSpacing: 1.2
  },
  titleTop: {
    backgroundColor: 'black',
    opacity: 0.8,
    color: 'white',
    padding: '15px 40px',
    lineHeight: 0.1,
    fontSize: 7,
    fontWeight: 200,
    letterSpacing: 3,
    paddingLeft: '30%'
  },
  buttonBox: {
    paddingTop: 20
  },

  // Add these new styles to your stylesheet (makeStyles)
overlay: {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backdropFilter: 'blur(5px)',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
  },
  overlayText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '1rem',
    textAlign: 'center',
    textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)'
  }
});

export default styles;
