import intl from 'react-intl-universal';
/**
 * I18N data constants.
 */
export const LABEL = 'label.';
export const APP_TITLE = 'app_title';
export const SECOND_GENOME = 'second_genome';
export const SIGN_UP = 'sign_up';
export const SIGN_IN = 'sign_in';
export const SITE_NAME = 'site_name';

// UI Components
export const EXPAND_BAR_EXPAND_LABEL = 'expand_bar_expand_label';
export const COMPONENT_INSTAGRAM_GALLERY_TITLE = 'component_instagram_gallery_title';

// Header
export const HEADER_INTROL = 'header_introl';
export const HEADER_CONTENT = 'header_content';
export const HEADER_ORGANIZE = 'header_organize';
export const HEADER_NETWORK = 'header_network';
export const HEADER_INTROL_SUB = 'header_introl_sub';
export const HEADER_CONTENT_SUB = 'header_content_sub';
export const HEADER_ORGANIZE_SUB = 'header_organize_sub';
export const HEADER_NETWORK_SUB = 'header_network_sub';
export const HEADER_TITLE_LEFT = 'header_title_left';
export const HEADER_TITLE_RIGHT = 'header_title_right';

export const HEADER_INTROL_MENU_HOME = 'header_introl_menu_home';
export const HEADER_INTROL_MENU_COMPASS = 'header_introl_menu_cc';
export const HEADER_INTROL_MENU_CHARTER = 'header_introl_menu_charter';
export const HEADER_INTROL_MENU_META = 'header_introl_menu_meta';
export const HEADER_INTROL_MENU_METAWHEEL = 'header_introl_menu_metawheel';

export const HEADER_CONTENT_MENU_QUOTES = 'header_content_menu_quotes';
export const HEADER_CONTENT_MENU_ARTICLES = 'header_content_menu_articles';
export const HEADER_CONTENT_MENU_VIDEOS = 'header_content_menu_videos';
export const HEADER_CONTENT_MENU_SANCTUARY = 'header_content_menu_sanctuary';
export const HEADER_CONTENT_MENU_GUIDES = 'header_content_menu_guides';

export const HEADER_ORGANIZE_MENU_HEALTH = 'header_org_menu_health';
export const HEADER_ORGANIZE_MENU_PROSPERITY = 'header_org_menu_prosperity';
export const HEADER_ORGANIZE_MENU_INSPAIRATION = 'header_org_menu_inspairation';
export const HEADER_ORGANIZE_MENU_JOY = 'header_org_menu_joy';

export const HEADER_NETWORK_MENU_CONTRIBUTE = 'header_network_menu_contribute';
export const HEADER_NETWORK_MENU_SPONSOR = 'header_network_menu_sponsor';
export const HEADER_NETWORK_MENU_DISPERSE = 'header_network_menu_disperse';
export const HEADER_NETWORK_MENU_PROFILE = 'header_network_menu_profile';

//SIGN UP FORM
export const AUTH_SIGN_UP_FORM_NAME_LABEL = 'auth_sign_up_form_name_label';
export const AUTH_SIGN_UP_FORM_NAME_TITLE = 'auth_sign_up_form_name_title';
export const AUTH_SIGN_UP_FORM_NAME_PLACEHOLDER = 'auth_sign_up_form_name_placeholder';
export const AUTH_SIGN_UP_FORM_PHONE_LABEL = 'auth_sign_up_form_phone_label';
export const AUTH_SIGN_UP_FORM_PHONE_TITLE = 'auth_sign_up_form_phone_title';
export const AUTH_SIGN_UP_FORM_PHONE_PLACEHOLDER = 'auth_sign_up_form_phone_placeholder';
export const AUTH_SIGN_UP_FORM_EMAIL_LABEL = 'auth_sign_up_form_email_label';
export const AUTH_SIGN_UP_FORM_EMAIL_TITLE = 'auth_sign_up_form_email_title';
export const AUTH_SIGN_UP_FORM_EMAIL_PLACEHOLDER = 'auth_sign_up_form_email_placeholder';
export const AUTH_SIGN_UP_FORM_PASSWORD_LABEL = 'auth_sign_up_form_password_label';
export const AUTH_SIGN_UP_FORM_PASSWORD_TITLE = 'auth_sign_up_form_password_title';
export const AUTH_SIGN_UP_FORM_PASSWORD_PLACEHOLDER = 'auth_sign_up_form_password_placeholder';
export const AUTH_SIGN_UP_FORM_CONFIRMED_PASSWORD_LABEL = 'auth_sign_up_form_confirmed_password_label';
export const AUTH_SIGN_UP_FORM_CONFIRMED_PASSWORD_TITLE = 'auth_sign_up_form_confirmed_password_title';
export const AUTH_SIGN_UP_FORM_CONFIRMED_PASSWORD_PLACEHOLDER = 'auth_sign_up_form_confirmed_password_placeholder';
export const AUTH_VERIFY_EXISTING_ACCOUNT = 'auth_verify_existing_account';
export const AUTH_SIGN_UP_FORM_DONATE_BOX = 'auth_sign_up_form_donate_box';
export const AUTH_SIGN_UP_FORM_PERMISSION_BOX = 'auth_sign_up_form_permission_box';
export const AUTH_SIGN_UP_FORM_BUTTON = 'auth_sign_up_form_button';
export const AUTH_SIGN_UP_FORM_ERROR_INVALID_PASSWORD = 'auth_sign_up_form_error_invalid_password';
export const AUTH_SIGN_UP_FORM_ERROR_INVALID_PHONE_NUMBER = 'auth_sign_up_form_error_invalid_phone_number';
export const AUTH_SIGN_UP_FORM_ERROR_INVALID_EMAIL_ADDRESS = 'auth_sign_up_form_error_invalid_email_address';
export const AUTH_SIGN_UP_FORM_TEXT_HEADING = 'auth_sign_up_form_text_heading';
export const AUTH_SIGN_UP_FORM_TEXT_CONTENT = 'auth_sign_up_form_text_content';
export const AUTH_SIGN_UP_FORM_TEXT_PRIVACY = 'auth_sign_up_form_text_privacy';

//VERIFY FORM
export const AUTH_VERIFICATION_CODE_MESSAGE = 'auth_verification_code_message';
export const AUTH_VERIFY_FORM_NAME_LABEL = 'auth_verify_form_name_label';
export const AUTH_VERIFY_FORM_NAME_TITLE = 'auth_verify_form_name_title';
export const AUTH_VERIFY_FORM_NAME_PLACEHOLDER = 'auth_verify_form_name_placeholder';
export const AUTH_VERIFY_FORM_CODE_LABEL = 'auth_verify_form_code_label';
export const AUTH_VERIFY_FORM_CODE_TITLE = 'auth_verify_form_code_title';
export const AUTH_VERIFY_FORM_CODE_PLACEHOLDER = 'auth_verify_form_code_placeholder';
export const AUTH_VERIFY_FORM_BUTTON = 'auth_verify_form_button';
export const AUTH_VERIFY_FORM_ERROR_CODE_MISMATCH = 'auth_verify_form_error_code_mismatch';
export const AUTH_VERIFY_FORM_ERROR_LIMIT_EXCEEDED = 'auth_verify_form_error_limit_exceeded';
export const AUTH_VERIFY_FORM_ERROR_CONFIRMED = 'auth_verify_form_error_confirmed';
export const AUTH_VERIFY_FORM_ERROR_USER_NOT_FOUND = 'auth_verify_form_error_user_not_found';

//SignUpSidebar

export const AUTH_SIGNUP_SIDEBAR_HEALTH_HEADING = 'auth_signup_sidebar_health_heading';
export const AUTH_SIGNUP_SIDEBAR_HEALTH_TEXT = 'auth_signup_sidebar_health_text';
export const AUTH_SIGNUP_SIDEBAR_SPONSOR_HEALTH_TEXT = 'auth_signup_sidebar_sponsor_health_text';
export const AUTH_SIGNUP_SIDEBAR_PROSPERITY_HEADING = 'auth_signup_sidebar_prosperity_heading';
export const AUTH_SIGNUP_SIDEBAR_PROSPERITY_TEXT = 'auth_signup_sidebar_prosperity_text';
export const AUTH_SIGNUP_SIDEBAR_INSPIRATION_HEADING = 'auth_signup_sidebar_inspiration_heading';
export const AUTH_SIGNUP_SIDEBAR_INSPIRATION_TEXT = 'auth_signup_sidebar_inspiration_text';
export const AUTH_SIGNUP_SIDEBAR_JOY_HEADING = 'auth_signup_sidebar_joy_heading';
export const AUTH_SIGNUP_SIDEBAR_JOY_TEXT = 'auth_signup_sidebar_joy_text';

//SIGN IN FORM
export const AUTH_SIGN_IN_EMAIL_LABEL = 'auth_sign_in_email_label';
export const AUTH_SIGN_IN_EMAIL_TITLE = 'auth_sign_in_email_title';
export const AUTH_SIGN_IN_EMAIL_PLACEHOLDER = 'auth_sign_in_email_placeholder';
export const AUTH_SIGN_IN_PASSWORD_LABEL = 'auth_sign_in_password_label';
export const AUTH_SIGN_IN_PASSWORD_TITLE = 'auth_sign_in_password_title';
export const AUTH_SIGN_IN_PASSWORD_PLACEHOLDER = 'auth_sign_in_password_placeholder';
export const AUTH_SIGN_IN_CHECK_BOX_LABEL = 'auth_sign_in_check_box_label';
export const AUTH_SIGN_IN_FORGOT_PASSWORD = 'auth_sign_in_forgot_password';
export const AUTH_SIGN_IN_LOGIN_BUTTON = 'auth_sign_in_login_button';
export const AUTH_SIGN_IN_ERROR_USER_NOT_FOUND = 'auth_sign_in_error_user_not_found';
export const AUTH_SIGN_IN_ERROR_USER_PASSWORD_MISMATCH = 'auth_sign_in_error_user_password_mismatch';
export const AUTH_SIGN_IN_ERROR_USER_DISABLED = 'auth_sign_in_error_user_disabled';

// Auth 
export const AUTH_ERROR_BAR_ERROR_LABEL = 'auth_error_bar_error_label'; 

//Organize UI component
export const ORGANIZE_QUESTIONBOX_QA = 'organize_questionbox_qa';
export const ORGANIZE_QUESTIONBOX_VIEWMORE_BUTTON = 'organize_questionbox_viewmore_button';

export const ORGANIZE_ARTICLEBOX_TITLE = 'organize_articlebox_title';

export const ORGANIZE_GUIDEBOX_TITLE = 'organize_guidebox_title';
export const ORGANIZE_GUIDEBOX_TAKEME_BUTTON = 'organize_questionbox_viewmore_button';

export const ORGANIZE_INITIATIVESBOX_TITLE = 'organize_initiativesbox_title';
export const ORGANIZE_INITIATIVESBOX_VISION = 'organize_initiativesbox_vision';
export const ORGANIZE_INITIATIVESBOX_MISSION = 'organize_initiativesbox_mission';
export const ORGANIZE_INITIATIVESBOX_CHARACTER = 'organize_initiativesbox_character';
export const ORGANIZE_INITIATIVESBOX_CREATION = 'organize_initiativesbox_creation';
export const ORGANIZE_INITIATIVESBOX_METAWHEEL = 'organize_initiativesbox_metawheel';
export const ORGANIZE_INITIATIVESBOX_INITIATIVES = 'organize_initiativesbox_initiatives';

//Organize Section content
export const ORGANIZE_SECTIONCONTENT_SHARE = 'organize_sectioncontent_share';
export const ORGANIZE_SECTIONCONTENT_VISIT = 'organize_sectioncontent_visit';
export const ORGANIZE_SECTIONCONTENT_GO_BUTTON = 'organize_sectioncontent_go_button';
export const ORGANIZE_SECTIONCONTENT_VISIT_BUTTON = 'organize_sectioncontent_visit_button';

//Organize Health Page
export const ORGANIZE_HEALTHPAGE_TITLE = 'organize_healthpage_title';
export const ORGANIZE_HEALTHPAGE_SUBTITLE_1 = 'organize_healthpage_subtitle_1';
export const ORGANIZE_HEALTHPAGE_SUBTITLE_2 = 'organize_healthpage_subtitle_2';

//Organize Prosperity Page
export const ORGANIZE_PROSPERITYPAGE_TITLE = 'organize_prosperitypage_title';
export const ORGANIZE_PROSPERITYPAGE_SUBTITLE_1 = 'organize_prosperitypage_subtitle_1';
export const ORGANIZE_PROSPERITYPAGE_SUBTITLE_2 = 'organize_prosperitypage_subtitle_2';

//Organize Joy Page
export const ORGANIZE_JOYPAGE_TITLE = 'organize_joypage_title';
export const ORGANIZE_JOYPAGE_SUBTITLE_1 = 'organize_joypage_subtitle_1';
export const ORGANIZE_JOYPAGE_SUBTITLE_2 = 'organize_joypage_subtitle_2';

//Organize Inspiration Page
export const ORGANIZE_INSPIRATIONPAGE_TITLE = 'organize_inspirationpage_title';
export const ORGANIZE_INSPIRATIONPAGE_SUBTITLE_1 = 'organize_inspirationpage_subtitle_1';
export const ORGANIZE_INSPIRATIONPAGE_SUBTITLE_2 = 'organize_inspirationpage_subtitle_2';

//Network Contribute Page
export const CONTRIBUTE_CONTENT_HEADING = 'contribute_content_heading';
export const CONTRIBUTE_CONTENT_INTRO_TEXT = 'contribute_content_intro_text';

export const CONTRIBUTE_CONTENT_TYPE = 'contribute_content_type';
export const CONTRIBUTE_CONTENT_TYPE_PERSONAL = 'contribute_content_type_personal';
export const CONTRIBUTE_CONTENT_TYPE_GLOBAL = 'contribute_content_type_global';
export const CONTRIBUTE_CONTENT_TYPE_POPOVER_TEXT_PERSONAL = 'contribute_content_type_popover_text_personal';
export const CONTRIBUTE_CONTENT_TYPE_POPOVER_TEXT_GLOBAL = 'contribute_content_type_popover_text_global';

export const CONTRIBUTE_CONTENT_TYPE_TITLE_QUOTE = 'contribute_content_type_title_quote';
export const CONTRIBUTE_CONTENT_TYPE_TITLE_ARTICLES = 'contribute_content_type_title_articles';
export const CONTRIBUTE_CONTENT_TYPE_TITLE_VIDEOS = 'contribute_content_type_title_videos';
export const CONTRIBUTE_CONTENT_TYPE_TITLE_MESSAGE = 'contribute_content_type_title_message';

export const CONTRIBUTE_UPLOAD_CONTENT_NOTE_HEADING = 'contribute_upload_content_note_heading';
export const CONTRIBUTE_UPLOAD_CONTENT_NOTE_TEXT_1 = 'contribute_upload_content_note_text_1';
export const CONTRIBUTE_UPLOAD_CONTENT_NOTE_TEXT_2 = 'contribute_upload_content_note_text_2';

export const CONTRIBUTE_UPLOAD_INPUT_HEADING_TEXT = 'contribute_upload_input_heading_text';
export const CONTRIBUTE_UPLOAD_INPUT_HEADING_IMAGE = 'contribute_upload_input_heading_image';
export const CONTRIBUTE_UPLOAD_INPUT_HEADING_VIDEO = 'contribute_upload_input_heading_video';
export const CONTRIBUTE_UPLOAD_INPUT_HINT_TEXT = 'contribute_upload_input_hint_text';
export const CONTRIBUTE_UPLOAD_INPUT_HINT_IMAGE = 'contribute_upload_input_hint_image';
export const CONTRIBUTE_UPLOAD_INPUT_HINT_VIDEO = 'contribute_upload_input_hint_video';

export const CONTRIBUTE_TERM_MODAL_LINK = 'contribute_term_modal_link';
export const CONTRIBUTE_TERM_MODAL_HEADING = 'contribute_term_modal_heading';
export const CONTRIBUTE_TERMS_HEADING_1 = 'contribute_terms_heading_1';
export const CONTRIBUTE_TERMS_HEADING_2 = 'contribute_terms_heading_2';
export const CONTRIBUTE_TERMS_TEXT = 'contribute_terms_text';
export const CONTRIBUTE_TERM_MODAL_CHECKBOX_LABEL = 'contribute_term_modal_checkbox_label';

export const CONTRIBUTE_FORM_BUTTON_UPLOAD = 'contribute_form_button_upload';
export const CONTRIBUTE_FORM_BUTTON_PREVIEW = 'contribute_form_button_preview';
export const CONTRIBUTE_FORM_BUTTON_SUBMIT = 'contribute_form_button_submit';

//Disperse page
export const HEADER_NETWORK_MENU_DISPERSE_LABEL = 'header_network_menu_disperse_label';
export const HEADER_NETWORK_MENU_DISPERSE_DESCRIPTION = 'header_network_menu_disperse_description';

//Creation Compass Page
export const HEADER_INTRO_COMPASS_PAGE_TITLE = 'header_intro_compass_page_title';

//Additional
export const ARTICLE_SHARE_LABEL = 'article_share_label';
export const ARTICLE_COMMENT_LABEL = 'article_comment_label';
export const BACK_TO_ALL = 'back_to_all';
export const TERMS_AND_CONDITIONS_ACCEPTED = 'terms_and_conditions_accepted';
export const TERMS_AND_CONDITIONS_NOT_ACCEPTED = 'terms_and_conditions_not_accepted';

//Intro metamorphosis
export const INTRO_META_TITLE = 'intro_meta_title';
export const INTRO_META_SUB_TITLE = 'intro_meta_subtitle';
export const INTRO_META_DESC_TITLE = 'intro_meta_desc_title';
export const INTRO_META_DESC_CONTENT = 'intro_meta_desc_content';
export const INTRO_META_QUOTE = 'intro_meta_quote';
export const INTRO_META_QUOTE_CONTENT = 'intro_meta_quote_content';
export const INTRO_META_ARTICLE_TITLE = 'intro_meta_article_title';
export const INTRO_META_ARTICLE_CONTENT = 'intro_meta_article_content';
export const INTRO_META_HUMAN_ADVANCE_TITLE = 'intro_meta_human_advance_title';
export const INTRO_META_HUMAN_ADVANCE_TITLE2 = 'intro_meta_human_advance_title2';
export const INTRO_META_HUMAN_ADVANCE_TITLE3 = 'intro_meta_human_advance_title3';
export const INTRO_META_HUMAN_ADVANCE_CONTENT = 'intro_meta_human_advance_content';
export const INTRO_META_HUMAN_ADVANCE_CONTENT2 = 'intro_meta_human_advance_content2';
export const INTRO_META_HUMAN_ADVANCE_CONTENT3 = 'intro_meta_human_advance_content3';
export const INTRO_META_SINGUP = 'intro_meta_signup';

//Intro/Charter page
export const INTRO_CHARTER_TITLE = 'intro_charter_title';
export const INTRO_CHARTER_SWITCH_LABEL = 'intro_charter_switch_label';

//Network/Sponsor Page
export const SPONSOR_TITLE = 'sponsor_title';
export const SPONSOR_SUBTITLE = 'sponsor_subtitle';
export const SPONSOR_CRITERIA = 'sponsor_criteria';
export const SPONSOR_DESCRIPTION = 'sponsor_description';
export const SPONSOR_NAME = 'sponsor_name';
export const SPONSOR_EMAIL = 'sponsor_email';
export const SPONSOR_PHONE = 'sponsor_phone';
export const SPONSOR_ORGANIZATION = 'sponsor_organization';
export const SPONSOR_WEBSITE = 'sponsor_website';
export const SPONSOR_ADDRESS = 'sponsor_address';
export const SPONSOR_DONATION_GUIDELINES = 'sponsor_donation_guidelines';
export const SPONSOR_READ_TERMS = 'sponsor_read_terms';
export const SPONSOR_DONATION_TYPE = 'sponsor_donation_type';
export const SPONSOR_D = 'sponsor_d';
export const SPONSOR_W = 'sponsor_w';
export const SPONSOR_M = 'sponsor_m';

//Content/Article Detail Page
export const ARTICLE_DETAIL_ADD_COMMENT_TITLE = 'article_detail_add_comment_title';
export const ARTICLE_DETAIL_ADD_COMMENT_FORM_NAME = 'article_detail_add_comment_form_name';
export const ARTICLE_DETAIL_ADD_COMMENT_FORM_EMAIL = 'article_detail_add_comment_form_email';
export const ARTICLE_DETAIL_ADD_COMMENT_FORM_MESSAGE = 'article_detail_add_comment_form_message';
export const ARTICLE_DETAIL_ADD_COMMENT_FORM_BUTTON_SUBMIT = 'article_detail_add_comment_form_button_submit';

export const ARTICLE_DETAIL_READER_COMMENTS_TITLE = 'article_detail_reader_comments_title';

export const ARTICLE_DETAIL_TOOLBAR_ACTION_SHARE = 'article_detail_toolbar_action_share';

export const ARTICLE_DETAIL_SHORTCUT_ACTION_ADD_TO_TREASURE_BOX = 'article_detail_shortcut_action_add_to_treasure_box';
export const ARTICLE_DETAIL_SHORTCUT_ACTION_VITALITY_LONGEVITY = 'article_detail_shortcut_action_vitality_longevity';
export const ARTICLE_DETAIL_SHORTCUT_ACTION_PRINT = 'article_detail_shortcut_action_print';

export const ARTICLE_DETAIL_LATEST_POSTS_TITLE = 'article_detail_latest_posts_title';
export const ARTICLE_DETAIL_LATEST_POSTS_FILTER_CHOOSE_CATEGORY_LABEL = 'article_detail_latest_posts_filter_choose_category_label';
export const ARTICLE_DETAIL_LATEST_POSTS_FILTER_CATEGORY_ALL = 'article_detail_latest_posts_filter_category_all';

export const ARTICLE_DETAIL_ABOUT_AUTHOR_TITLE = 'article_detail_about_author_title';
export const ARTICLE_DETAIL_ABOUT_AUTHOR_BUTTON_MESSAGE = 'article_detail_about_author_button_message';
export const ARTICLE_DETAIL_ABOUT_AUTHOR_BUTTON_BOOK = 'article_detail_about_author_button_book';




export const locales = {
  'en-US': require('./en-US'),
  'de-DE': require('./de-DE')
};

export function loadLocales(lang = 'en-US') {
  return intl.init({
    currentLocale: lang,
    locales
  });
}
