import React, {useState} from 'react';
import {IconButton, Avatar, Popover, Typography, Badge, withStyles} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import {makeStyles} from '@material-ui/core/styles';
import {bindHover, bindToggle, usePopupState} from 'material-ui-popup-state/hooks';
import {isMobile} from 'react-device-detect';
import UserInfoPopover from '../UserInfoPopover';
import {styles} from './UserInfoIconButtonsStyles';

import ButterflyImage from '../../../../assets/images/icons/butterflyAppBar.png';
import MetaWheelImage from '../../../../assets/images/icons/metawheelAppBar.png';
import appIcon from '../../../../assets/images/icons/apps.png';

import AppDrawerPopOver from 'shared/appDrawer';

const useStyles = makeStyles(styles);

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#0099ff',
    color: 'white',
    padding: '0',
    margin: 'auto 4px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: -2, 
    },
  }
}))(Badge);

const UserInfoIconButtons = () => {
  const [appanchorEl, setAppAnchorEl] = useState(null);

  const handelPopoverOpen = (e, setAnchorEI) => {
    setAnchorEI(e.currentTarget);
  };
  const handelPopoverClose = (setAnchorEI) => {
    setAnchorEI(null);
  };

  const open = (anchorEI) => {
    return Boolean(anchorEI);
  };
  const classes = useStyles();
  const userInfoPopupState = usePopupState({variant: 'popover', popupId: 'userInfoPopover'});

  return (
    <>
      <div className={classes.iconButtonContainer}>
        <IconButton className={classes.metaButton} color="inherit" aria-label="MetaWheel" size={'medium'}>
          <Avatar alt="MetaWheel" src={MetaWheelImage} className={classes.metaIcon} />
        </IconButton>
        <IconButton
          className={classes.butterflyButton}
          color="inherit"
          aria-label="Butterfly"
          size={'medium'}
          {...(isMobile ? bindToggle(userInfoPopupState) : bindHover(userInfoPopupState))}
        >
          <Avatar alt="Butterfly" src={ButterflyImage} className={classes.butterflyIcon} />
        </IconButton>
        <UserInfoPopover popupState={userInfoPopupState} />
      </div>
      <div className={classes.appDrawerIconButton}>
        <IconButton onClick={(e) => handelPopoverOpen(e, setAppAnchorEl)}>
          {' '}
          <StyledBadge badgeContent={null} className={classes.badge} overlap="rectangular">
            <img src={appIcon} alt="apps" className={classes.icons} />
          </StyledBadge>
        </IconButton>
        <Popover
          open={open(appanchorEl)}
          anchorEl={appanchorEl}
          onClose={() => handelPopoverClose(setAppAnchorEl)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
        <div style={{ position: "relative", padding: "16px" }}>
          {/* Close Button */}
          <IconButton
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
                onClick={() => handelPopoverClose(setAppAnchorEl)}
              >
                <CloseIcon />
          </IconButton>

          {/* Original Content */}
          <AppDrawerPopOver />
        </div>
            
        </Popover>
      </div>
    </>
  );
};

export default UserInfoIconButtons;
