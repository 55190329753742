import * as React from 'react';
import clsx from 'clsx';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import {Card, CardContent, Typography, Button, Link} from '@material-ui/core';
import {SelfAligningImage} from '~/components/ui';
import styles from './InitiativeCardBoxStyles';

const TopButton = withStyles({
  root: {
    position: 'absolute',
    right: 20,
    top: 20,
    color: 'white',
    fontSize: 9,
    fontWeight: 700,
    letterSpacing: 1.4,
    borderRadius: 3,
    padding: '8px 10px 6px 10px',
    backgroundColor: '#5fd26e',
    '&:hover': {
      backgroundColor: '#5fd26e'
    }
  }
})(Button);

const useStyles = makeStyles(styles);

const InitiativeCardBox = (props) => {
  const classes = useStyles();
  const {handleClick, className} = props;
  const {id, image, title, type, titleTop} = props.item;

  return (
    <Link className={clsx(classes.root, className)} onClick={handleClick}>
      <Card className={classes.container} elevation={0} square={true}>
        <SelfAligningImage src={image} alt={title} />
        {titleTop && <TopButton onClick={() => handleClick(id)}>{titleTop}</TopButton>}
        <CardContent className={classes.contentContainer}>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.type}>{type}</Typography>
        </CardContent>

        {/* Add overlay */}
        <div className={classes.overlay}>
          <Typography variant="h5" className={classes.overlayText}>
            FEATURE COMING SOON
          </Typography>
        </div>
      </Card>
    </Link>
  );
};

export default InitiativeCardBox;
