const styles = (theme) => ({
  longPaper: {
    transition: 'all 0.5s ease-in-out',
    height: 'fit-content',
    overflow: 'hidden',
  },

  shortPaper: {
    transition: 'all 0.5s ease-in-out',
    height: '380px',
    overflow: 'hidden',
    height: 'fit-content'
  }
});

export default styles;
