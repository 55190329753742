import React from 'react';
import * as messageKeys from '~/locales';
import intl from 'react-intl-universal';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

const useStyles = (fontColor) =>
  makeStyles({
    visitText: {
      textAlign: 'center',
      fontSize: '1.8em',
      fontWeight: 'bold',
      opacity: '0.67',
      color: `${fontColor}`,
      lineHeight: '1.6',
      letterSpacing: '5px'
    },
    workshopBtn: {
      margin: '5em auto 0',
      width: 'fit-content',
      height: '28px',
      padding: '10px',
      border: 'solid 2px #d2a75f',
      fontSize: '0.8em',
      lineHeight: '2.2',
      textAlign: 'center',
      letterSpacing: '1.5px',
      '&:hover': {
        backgroundColor: '#d2a75f'
      }
    },
    textBelow: {
      marginTop: '1em',
      textAlign: 'center',
      fontSize: '1.2em',
      fontWeight: 'bold',
      color: 'rgba(255,255,255,0.67)',
      lineHeight: '1.6',
      letterSpacing: '5px',
      '&:hover': {
        color: 'rgba(255,255,255,1)'
      }
    },
    linkStyle: {
      textDecoration: 'none',
      color: 'white',
      fontWeight: 'bold'
    }
  });

function SeeWorkShopBox(props) {
  const classes = useStyles(props.themeColor)();
  return (
    <div>
      <Typography className={classes.visitText}>{props.title}</Typography>
      <div className={classes.workshopBtn}>
        <a href={props.buttonLink} className={classes.linkStyle}>
          {props.buttonText ? props.buttonText : intl.get(messageKeys.ORGANIZE_SECTIONCONTENT_VISIT_BUTTON)}
        </a>
      </div>
       {/* New Text Below the Button */}
      <Typography className={classes.textBelow}>
        {props.additionalText ? props.additionalText : ""}
      </Typography>
    </div>
  );
}

export default SeeWorkShopBox;
