import React from 'react';
import {withRouter} from 'react-router';
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import styles from './SectionHeadingStyle';
// Styled Component
import {Typography, Grid} from '@material-ui/core';
import {Sphere} from '~/components/ui';

const useStyles = makeStyles(styles);

function SectionHeading({humanImage, title, description, subHeading, history}) {
  const classes = useStyles();
  const pathname = history.location.pathname;

  return (
    <Grid container className={classes.content}>
      <Grid item md={8} sm={8} xs={12} className={classes.headingPart}>
        <div className={classes.humanimg}>
          <img src={humanImage} className={classes.img} alt="health body" />
        </div>
        <Typography className={classes.mainHeading}>{title}</Typography>
        {pathname === '/organize/health' ? <div className={classes.healthLinkContainer}>{subHeading()}</div> : null}
        {pathname === '/organize/prosperity' ? (
          <div className={classes.prosperityLinkContainer}>{subHeading()}</div>
        ) : null}
        {pathname === '/organize/inspiration' ? (
          <div className={classes.inspirationLinkContainer}>{subHeading()}</div>
        ) : null}
        {pathname === '/organize/joy' ? <div className={classes.joyLinkContainer}>{subHeading()}</div> : null}
        <Typography className={classes.contentDescription}
        dangerouslySetInnerHTML={{__html: description}}
        ></Typography>
      </Grid>
      
    </Grid>
  );
}

export default withRouter(SectionHeading);
