import React from 'react';
import * as messageKeys from '~/locales';
import intl from 'react-intl-universal';
import {styles} from './JoyStyle';
import ClusterIcons from 'shared/ui/cluster-icons';
// Styled Component
import {Container, withStyles} from '@material-ui/core';
import SectionHeading from '../Sections/SectionHeading';
import SectionContent from '../Sections/SectionContent';
// Resources
import humanImage from '~/assets/images/layout/landing/personJoy.png';
import articleBg from '~/assets/images/organize/joy_article.jpg';
import videoBg from '~/assets/images/organize/joy_video.jpg';
import InitiativesBg from '~/assets/images/organize/joy_initiatives.jpg';

// Heading mock data
const title = messageKeys.ORGANIZE_JOYPAGE_TITLE;
const subHeadingData = [messageKeys.ORGANIZE_JOYPAGE_SUBTITLE_1, messageKeys.ORGANIZE_JOYPAGE_SUBTITLE_2];

const themeColor = '#fec503';
const btnColor = '#fec503';

// Section content mock data
const description =
  '<b>Quotes & Affirmations, Articles, Videos, and Guides</b> centred around Joy. Our approach integrates <a href="#">Clarity & Beauty</a>—the six core components of one’s higher self—with a global perspective of <a href="#">Spiritual Authenticity</a>, which cultivates Universal Truths to unite and elevate humanity through a lens of oneness. Together, these elements support our mission of <a href="https://vfh.org.nz/">Elevating Global Consciousness</a> by fostering societal and spiritual growth, strengthening our shared sense of connection and humanity.';
const questions = [
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  'Quisque tempus enim eu eros molestie, eget porta tellus fermentum. Pellentes in ultricies tortor, ac fringilla libero.'
];
const articleContent =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus scelerisque luctus nibh ornare ultricies. Sed consequat commodo urna, eu pellentesque enim luctus quis. Suspendisse arcu elit, ullamcorper non mauris et, hendrerit consectetur sem. Aenean ac pretium turpis. Pellentesque vitae justo luctus, sodales enim a, suscipit libero. Sed consequat euismod convallis.  Donec auctor augue id ipsum dictum, vitae euismod……..';

const videoDescription =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla purus velit, aliquet sed quam sit amet, pellentesque porttitor eros. In hac habitasse platea dictumst.';
const videoName = 'VIDEO NAME';

const guideContent =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed orci elit, tincidunt quis ligula a, semper molestie tortor. Ut ut libero ullamcorper, tincidunt risus nec, dapibus felis. Morbi tortor dolor, posuere placerat sapien nec, condimentum varius leo. Pellentesque at fringilla risus, ut ultricies sapien. Nunc rhoncus nunc non purus congue, et dictum risus pulvinar. Donec non ante quam. Pellentesque bibendum, turpis et laoreet mattis, quam libero volutpat augue, a consequat diam nulla eget nisi. ';

function Joy({classes}) {
  const subHeading = () => {
    return (
      <React.Fragment>
        <div className={classes.oneLink}>
          <span>{intl.get(subHeadingData[0])}</span>
        </div>
        <div className={classes.oneLink}>|</div>
        <div className={classes.oneLink}>
          <span>{intl.get(subHeadingData[1])}</span>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className={classes.backgroundWrapper}>
      <ClusterIcons active/>
      <div className={classes.content}>
        <Container maxWidth="lg">
          <SectionHeading
            humanImage={humanImage}
            title={intl.get(title)}
            description={description}
            subHeading={subHeading}
          ></SectionHeading>{' '}
          <SectionContent
            quote="“Authentic beauty and oneness emerge through love, forgiveness, compassion, tolerance, and integrity.”"
            author="Kim Larking"
            questions={questions}
            articleContent={articleContent}
            textColor={themeColor}
            themeColor={themeColor}
            btnColor={btnColor}
            btnTitle={intl.get(title)}
            articleBg={articleBg}
            videoBg={videoBg}
            videoDescription={videoDescription}
            videoName={videoName}
            guideContent={guideContent}
            initiativesBg={InitiativesBg}
          />
        </Container>
      </div>
    </div>
  );
}

export default withStyles(styles)(Joy);
