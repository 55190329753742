import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import ArticlesMain from "./Sections/ArticlesMain";
import styles from "./ArticlesMainPageStyles";
import { tableTitles } from "~/data/articlesData";
import ClusterIcons from 'shared/ui/cluster-icons';

const ArticlesMainPage = ({ classes }) => {
  const history = useHistory();

  const [selectedTitleIds, setSelectedTitleIds] = useState([]);

  const goDetail = (e) => {
    history.push({
      pathname: `/content/articles/${e.slug}`,
    });
  };

  const handleTitlesChange = (selectedTitleIds) => {
    setSelectedTitleIds(selectedTitleIds);
  };

  return (
    <div className={classes.backgroundWrapper}>
      <ClusterIcons active />
      <div className={classes.content}>
        <ArticlesMain
          goDetail={goDetail}
          titles={tableTitles}
          selectedTitleIds={selectedTitleIds}
          onTitlesChange={handleTitlesChange}
        />
      </div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(ArticlesMainPage);
