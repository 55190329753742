import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';

import GuidesMain from './Sections/GuidesMain';
import GuidesMainExpanded from './Sections/GuidesMainExpanded';
import ClusterIcons from 'shared/ui/cluster-icons';
import {styles} from './GuidesMainPageStyles';

//TODO
import {guidesPersonData, guidesPlanetData, typeListData} from '~/data/guidesData';
import guidesPersonBackground from '~/assets/images/content/guides/guides_bg.png';
//TODO change background picture
import guidesPlanetBackground from '~/assets/images/content/guides/guides_planet_bg.png';

const PERSON = 'person';
const PLANET = 'planet';

class Guides extends Component {
  state = {
    expanded: false,
    mode: PERSON,
    selectIndex: -1
  };

  goExpanded = (e) => {
    this.setState((preState) => ({expanded: !preState.expanded}));
    this.props.history.push(`?expanded=true&mode=${this.state.mode}`);
  };

  setExpended = () => {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const expanded = Boolean(params.get('expanded') && params.get('expanded').toUpperCase() === 'TRUE');
    const mode = Boolean(params.get(PLANET)) ? PLANET : PERSON
    this.setState((prev) => {
      if (mode !== prev.mode || expanded !== prev.expanded) {
        return {
          mode,
          expanded
        }
      } else {
        return null
      }
    })
  };

  componentDidMount() {
    const path = this.props.match.path;
    let mode = PERSON;
    if (path.endsWith(PERSON)) {
      mode = PERSON;
    } else if (path.endsWith(PLANET)) {
      mode = PLANET;
    }
    this.setState((prev) => ({...prev, mode}));

    this.setExpended();
  }

  onFilterItemClick = (index) => {
    let selectIndex = index;
    if (this.state.selectIndex === index) {
      selectIndex = -1;
    }
    this.setState({...this.state, selectIndex});
  };

  componentDidUpdate() {
    this.setExpended();
  }

  render() {
    const {classes} = this.props;

    const background = this.state.mode === PERSON ? guidesPersonBackground : guidesPlanetBackground;
    let data = this.state.mode === PERSON ? guidesPersonData : guidesPlanetData;
    if (this.state.selectIndex >= 0) {
      data = data.filter((item) => item.tag && item.tag.text === typeListData[this.state.selectIndex].type);
    }
    return (
      <div className={classes.backgroundWrapper} style={{backgroundImage: `url(${background})`}}>
        <ClusterIcons active />
        <div className={classes.content}>
          {this.state.expanded ? (
            <GuidesMainExpanded
              data={data}
              typeListData={typeListData}
              selectIndex={this.state.selectIndex}
              onFilterItemClick={this.onFilterItemClick}
            />
          ) : (
            <GuidesMain
              goExpanded={this.goExpanded}
              data={data}
              mode={this.state.mode}
              typeListData={typeListData}
              selectIndex={this.state.selectIndex}
              onFilterItemClick={this.onFilterItemClick}
              handleSwitch={() => {
                const nextMode = this.state.mode === PERSON ? PLANET : PERSON
                this.setState({mode: nextMode});
                this.props.history.replace(`?${nextMode}=true`);
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Guides);
