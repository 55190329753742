import React, { createContext, useContext, useState, useEffect } from 'react';
import { useTokenRenewCustomer } from '../react-query/hooks';
import { useQuery, useQueryClient } from 'react-query';
import { isNil } from 'lodash';
import { fetchProfile } from '../react-query/strapiAPI';

const AuthContext = createContext(null);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ token: null, expiresAt: null });
  const tokenRenewCustomer = useTokenRenewCustomer();
  const [isLoading, setIsLoading] = useState(true);
  const queryClient = useQueryClient();

  const { data: profileData, isLoading: profileIsLoading, isError: profileIsError } = useQuery(
    ['visionaireProfile', auth.token],
    () => fetchProfile(auth.token),
    { enabled: !isNil(auth.token), refetchOnWindowFocus: false }
  );

  // Function to update the auth state and local storage
  const setAuthToken = (token, expiresAt) => {
    localStorage.setItem('token', token);
    localStorage.setItem('expiresAt', expiresAt);
    setAuth({ token, expiresAt });
  };

  // Function to renew the token
  const renewToken = async () => {
    const res = await tokenRenewCustomer.mutateAsync({ customerAccessToken: auth.token });
    if (res?.customerAccessTokenRenew?.customerAccessToken?.accessToken) {
      setAuthToken(res.customerAccessTokenRenew.customerAccessToken.accessToken, res.customerAccessTokenRenew.customerAccessToken.expiresAt);
    } else {
      await logout();
    }
  };

  // Function to log out
  const logout = async () => {
    // invalidate data related to this profile
    await queryClient.invalidateQueries(queryClient.invalidateQueries({
      predicate: query => query.queryKey[0] === profileData?.slug || query.queryKey[1] === profileData?.slug
    }));

    localStorage.removeItem('token');
    localStorage.removeItem('expiresAt');
    setAuth({ token: null, expiresAt: null });
  };

  // Effect to auto-renew the token before expiry
  useEffect(() => {
    if (!!auth.expiresAt) {

      const expiresIn = new Date(auth.expiresAt).getTime() - new Date().getTime();
      // https://stackoverflow.com/questions/3468607/why-does-settimeout-break-for-large-millisecond-delay-values
      // big expiry time will cause setTimeout to fire immediately
      if (Number.MAX_VALUE < expiresIn - 60000) {
        const timeout = setTimeout(renewToken, expiresIn - 60000); // Renew 1 minute before expiry
        return () => clearTimeout(timeout);
      }

    }
  }, [auth.expiresAt]);

  // Effect to initialize auth state from local storage
  useEffect(() => {
    const token = localStorage.getItem('token');
    const expiresAt = localStorage.getItem('expiresAt');
    if (token && expiresAt) {
      setAuth({ token, expiresAt });
    }
  }, []);

  // Effect to handle profile fetch error
  useEffect(() => {
    if (profileIsError) {
      logout();
    }
  }, [profileIsError]);

  // Effect to set isLoading based on profile fetching and token renewal
  useEffect(() => {
    if (profileIsLoading || tokenRenewCustomer.isLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [profileIsLoading, tokenRenewCustomer.isLoading]);

  return (
    <AuthContext.Provider value={{ auth, profileData, isLoading, setAuthToken, logout }}>
      {children}
    </AuthContext.Provider>
  );
};